<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="isVisible"
    :before-close="handleClose"
    :detailId="detailId">
    <el-form ref="form" :model="categoryData" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="父类" prop="pid">
            <el-cascader
              v-model="pidValue"
              :options="pidOptions"
              :props="{ checkStrictly: true }"
              @change="handleChange">
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类名" prop="category_name">
            <el-input v-model="categoryData.category_name" placeholder="类名"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort_num">
            <el-input-number v-model="categoryData.sort_num" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="路由地址" prop="route_addr">
            <el-input v-model="categoryData.route_addr"  placeholder="路由地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="展示" prop="status">
            <el-radio v-model="categoryData.status" label="1">是</el-radio>
            <el-radio v-model="categoryData.status" label="0">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="标题" prop="seo_title">
            <el-input v-model="categoryData.seo_title"  placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="关键字" prop="seo_keywords">
            <el-input v-model="categoryData.seo_keywords"  placeholder="关键字"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="描述" prop="seo_description">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="描述"
              v-model="categoryData.seo_description">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import tool from "@/utils/utils"
export default {
   name: "CategoryAddUpdate",
  data(){
      return {
        categoryData:{
          id:0,
          pid:0,
          level:"",
          category_name:"",
          route_addr:"",
          sort_num:1,
          status:"1",
          seo_title:"",
          seo_description:"",
          seo_keywords:"",
        },
        rules:{
          category_name:[
            { required: true, message: '请输入类名', trigger: 'blur' },
          ],
          seo_title:[
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],
          seo_description:[
            { required: true, message: '请输入描述', trigger: 'blur' },
          ],
          seo_keywords:[
            { required: true, message: '请输入关键字', trigger: 'blur' },
          ],
        },
        //分页数据
        pageInfo:{
          //当前页
          currentPage:1,
          //每页显示条数
          pageSize:-1,
        },
        pidValue:[],
        pidOptions:[
          {
            value: 0,
            label: "顶级",
            children:[],
          }
        ],
      }
  },
  //定义接受父组件传来的数据
  props:{
    isVisible:Boolean,
    title:String,
    detailId:Number,
  },
   methods:{
     query(){
       this.$http({
         method: 'post',
         url: '/category/custom_list',
         data: {
           page:this.pageInfo.currentPage,
           size:this.pageInfo.pageSize,
         },
       }).then(res=>{
         if (res.data.code==0) {
           this.pidOptions[0].children = tool.getTreeData(res.data.data.list)
         }else{
           this.$message.error(res.data.msg)
         }
       }).catch(err=>{
         console.log(err)
       })
     },
     //关闭之前的处理方法,向父组件传一个ChangVisible事件，false值,从而隐藏弹框
     handleClose(){
       this.$emit("ChangeVisible",false)
     },
     //根据id来判断是添加还是更新
     handleSubmit(){
           if (this.detailId!=0){
             this.$refs.form.validate((val)=>{
               if (!val){
                 this.$message.error("请输入必填项")
                 return false
               }
               this.categoryData.status = Number(this.categoryData.status)
               this.$http({
                 method: 'post',
                 url: '/category/update',
                 data: this.categoryData,
               }).then(res=>{
                 if (res.data.code==0) {
                   //清空表单
                   this.$refs.form.resetFields()
                   //关闭弹窗
                   this.handleClose()
                   //消息提示
                   this.$message.success("操作成功")
                   //子传父，调用父组件的query方法，重新加载数据
                   this.$emit("query")
                 }else{
                   this.$message.error(res.data.msg)
                 }
               }).catch(err=>{
                 console.log(err)
               })
             })
           }else{
             this.$refs.form.validate((val)=>{
                if (!val){
                  this.$message.error("请输入必填项")
                  return false
                }
               this.categoryData.status = Number(this.categoryData.status)
               this.$http({
                   method: 'post',
                   url: '/category/add',
                   data: this.categoryData,
                 }).then(res=>{
                   if (res.data.code==0) {
                     //清空表单
                     this.$refs.form.resetFields()
                     //关闭弹窗
                     this.handleClose()
                     //消息提示
                     this.$message.success("操作成功")
                     //子传父，调用父组件的query方法，重新加载数据
                     this.$emit("query")
                   }else{
                     this.$message.error(res.data.msg)
                   }
                 }).catch(err=>{
                   console.log(err)
               })

             })
           }
     },
     //父类选择
     handleChange(val){
        this.categoryData.pid = val[val.length-1]
        this.categoryData.level = val.join()
     },
     //清空表单数据
     handleFormData(){
       this.$refs.form.resetFields()
       this.pidValue = []
     },
   },
  mounted() {
    this.query()
  },
  watch:{
    detailId(val){
      if (val==0){
        return
      }
      this.query()
      this.$http({
        method: 'post',
        url: '/category/detail',
        data: {
          id: val
        },
      }).then(res=>{
        if (res.data.code==0) {
             let regObj = new RegExp(",")
             let pidValue = res.data.data.level.split(regObj).map(item=>Number(item))
                 pidValue.push(res.data.data.id)
             this.categoryData = res.data.data
             this.categoryData.status = String(res.data.data.status)
             this.pidValue = pidValue
          console.log(this.pidValue)
        }else {
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
