<template>
<div>
  <el-form :inline="true">
    <el-form-item><el-button type="primary" @click="handleSubmit">新增分类</el-button></el-form-item>
  </el-form>
  <el-table
    :data="CategoryList"
    stripe
    row-key="id"
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    style="width: 100%">
    <el-table-column
      prop="category_name"
      label="类名">
    </el-table-column>
    <el-table-column
      prop="route_addr"
      label="路由地址">
    </el-table-column>
    <el-table-column
      prop="status"
      label="状态">
      <template slot-scope="scope">
        <el-switch
          v-model="scope.row.status==1"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </template>
    </el-table-column>
    <el-table-column
      prop="sort_num"
      label="排序号">
    </el-table-column>
    <el-table-column
      prop="seo_title"
      label="seo标题">
    </el-table-column>
    <el-table-column
      prop="seo_keywords"
      label="seo关键字">
    </el-table-column>
    <el-table-column
      prop="create_time"
      label="创建时间">
    </el-table-column>
    <el-table-column
      prop="update_time"
      label="更新时间">
    </el-table-column>
    <el-table-column
      width="355"
      label="操作">
      <!--
      <template> 上使用特殊的 slot-scope attribute，可以接收传递给插槽的 prop
        对象会作为 scope 变量存在于 <template> 作用域中
       -->
      <template slot-scope="scope">
        <el-button type="warning"  icon="el-icon-edit" @click="handleDetail(scope.row.id)">编辑</el-button>
        <el-button type="danger" icon="el-icon-delete" @click="handleSingleDelete(scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!--分页 -->
<!--  <el-pagination
    :page-size="pageInfo.pageSize"
    layout="prev,pager,next"
    @current-change="handleCurrentChange"
    :total="pageInfo.total">
  </el-pagination>-->
  <!--编辑添加子组件-->
  <category-add-update ref="addUpdate" :isVisible="popWin.isVisible" :title="popWin.title" :detailId="detailId" @ChangeVisible="handleChangeVisible" @query="query"></category-add-update>
</div>
</template>

<script>
import CategoryAddUpdate from "@/views/category/CategoryAddUpdate";
export default {
  name: "Category",
  data(){
     return {
        //列表数据
         CategoryList:[
         ],
       //全选数据
         multipleSelection: [],
       //分页数据
       pageInfo:{
         //当前页
         currentPage:1,
         //每页显示条数
         pageSize:-1,
       },
       popWin:{
         isVisible:false,
         title:"",
       },
       detailId:0,
     }
  },
  methods:{
    query(){
      this.$http({
        method: 'post',
        url: '/category/list',
        data: {
          page:this.pageInfo.currentPage,
          size:this.pageInfo.pageSize,
        },
      }).then(res=>{
        if (res.data.code==0) {
          this.CategoryList = res.data.data.list
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //添加
    handleSubmit(){
          this.popWin.isVisible = true
          this.popWin.title = "添加"
          this.detailId = 0
          this.$refs.addUpdate.handleFormData()
    },
    //详情
    handleDetail(val){
      console.log("detail",val)
      this.popWin.isVisible = true
      this.popWin.title = "编辑"
      this.detailId = val
    },
    //单删
    handleSingleDelete(val){
      this.$confirm('确定要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'post',
          url: '/category/delete',
          data: {
            id: val,
          },
        }).then(res=>{
          if (res.data.code==0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.query()
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
        }).catch(err=>{
          console.log(err)
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //批量删除
    handleBatchDelete(){

    },
    //全选
   /* handleSelectionChange(){
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },*/
    //分页
  /*  handleCurrentChange(page){
      this.pageInfo.currentPage = page

    },*/
    //监听子组件事件
    handleChangeVisible(){
      this.popWin.isVisible = false
    }
  },
  //生命周期函数,页面加载完毕拉取后台数据
  mounted() {
    this.query()
  },
  components:{
    CategoryAddUpdate,
  }
}
</script>

<style scoped>

</style>

